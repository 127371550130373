import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { storage, db } from '../../../components/Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import dayjs from 'dayjs';
import countries from 'i18n-iso-countries'; // Install: `npm install i18n-iso-countries`

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const countryList = Object.entries(countries.getNames('en')).map(([code, name]) => ({ code, name }));

const PassportDetailsForm = ({ userEmail }) => {
  const [formData, setFormData] = useState({
    passportNumber: '',
    issueDate: null,
    expiryDate: null,
    issueCountry: '',
    placeOfBirth: '',
    nationality: '',
    scannedImage: null,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPassportDetails = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'students', userEmail);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data().passportDetails || {};
          setFormData({
            passportNumber: data.passportNumber || '',
            issueDate: data.issueDate ? dayjs(data.issueDate, 'MM/DD/YYYY') : null,
            expiryDate: data.expiryDate ? dayjs(data.expiryDate, 'MM/DD/YYYY') : null,
            issueCountry: data.issueCountry || '',
            placeOfBirth: data.placeOfBirth || '',
            nationality: data.nationality || '',
            scannedImage: null, // File can't be prefilled
          });
        }
      } catch (error) {
        console.error('Error fetching passport details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPassportDetails();
  }, [userEmail]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.passportNumber) newErrors.passportNumber = 'Passport number is required';
    if (!formData.issueDate) newErrors.issueDate = 'Issue date is required';
    if (!formData.expiryDate) newErrors.expiryDate = 'Expiry date is required';
    if (!formData.issueCountry) newErrors.issueCountry = 'Issue country is required';
    if (!formData.nationality) newErrors.nationality = 'Nationality is required';
    if (!formData.scannedImage) newErrors.scannedImage = 'Scanned image is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (field, date) => {
    setFormData({ ...formData, [field]: date });
  };

  const handleFileUpload = (e) => {
    setFormData({ ...formData, scannedImage: e.target.files[0] });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const fileName = `passport_${Date.now()}.pdf`;
      const storageRef = ref(storage, `students/${userEmail}/passport/${fileName}`);
      await uploadBytes(storageRef, formData.scannedImage);

      const docRef = doc(db, 'students', userEmail);
      await updateDoc(docRef, {
        passportDetails: {
          passportNumber: formData.passportNumber,
          issueDate: formData.issueDate ? formData.issueDate.format('MM/DD/YYYY') : '',
          expiryDate: formData.expiryDate ? formData.expiryDate.format('MM/DD/YYYY') : '',
          issueCountry: formData.issueCountry,
          placeOfBirth: formData.placeOfBirth || '',
          nationality: formData.nationality,
          scannedImage: fileName,
        },
      });

      alert('Passport details successfully updated!');
    } catch (error) {
      console.error('Error updating passport details:', error);
      alert('Failed to update passport details.');
    }
  };

  if (loading) {
    return <Typography>Loading passport details...</Typography>;
  }

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Passport Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Passport Number"
            name="passportNumber"
            fullWidth
            value={formData.passportNumber}
            onChange={handleInputChange}
            error={!!errors.passportNumber}
            helperText={errors.passportNumber}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Issue Date"
              value={formData.issueDate}
              onChange={(date) => handleDateChange('issueDate', date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.issueDate}
                  helperText={errors.issueDate}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expiry Date"
              value={formData.expiryDate}
              onChange={(date) => handleDateChange('expiryDate', date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.expiryDate}
                  helperText={errors.expiryDate}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Issue Country"
            name="issueCountry"
            fullWidth
            value={formData.issueCountry}
            onChange={handleInputChange}
            error={!!errors.issueCountry}
            helperText={errors.issueCountry}
          >
            {countryList.map(({ code, name }) => (
              <MenuItem key={code} value={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Place of Birth"
            name="placeOfBirth"
            fullWidth
            value={formData.placeOfBirth}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Nationality"
            name="nationality"
            fullWidth
            value={formData.nationality}
            onChange={handleInputChange}
            error={!!errors.nationality}
            helperText={errors.nationality}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" component="label" fullWidth>
            Upload Scanned Image
            <input
              type="file"
              accept=".pdf"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          {errors.scannedImage && (
            <Typography color="error" variant="caption">
              {errors.scannedImage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PassportDetailsForm;
