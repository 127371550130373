import React from 'react';
import { Box, Typography } from '@mui/material';
import { PolarArea } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

function CardExpenseStatistics() {
  // Chart data
  const data = {
    labels: ['In Review', 'Rejected', 'Approved', 'Pending'],
    datasets: [
      {
        data: [50, 20, 30, 10], // Values for each section
        backgroundColor: ['#4285F4', '#FB7E81', '#00C49F', '#FFC107'], // Colors
        borderColor: ['#3367D6', '#F06263', '#00B388', '#E2A207'], // Border colors
        borderWidth: 1, // Thickness of the border
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom', // Legend position
        labels: {
          usePointStyle: true, // Dot legend style
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}%`,
        },
      },
    },
    scales: {
      r: {
        ticks: {
          display: false, // Hide axis labels
        },
        grid: {
          circular: true, // Circular grid lines
        },
      },
    },
  };

  return (<>
    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
      Recent Applications Overview
    </Typography>
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: 4,
        padding: '16px',
        textAlign: 'center',
        maxWidth: '400px',
        margin: '0 auto',
      }}
    >
      <PolarArea data={data} options={options} />
    </Box>
  </>);
}

export default CardExpenseStatistics;
