import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { auth, db } from '../components/Firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import bannerImage from '../assets/banner_image.jpg';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import MuiSnackbar from '../components/SnackBar';
import { resendVerifyEmail, updateUserEmailVerifiedStatus } from '../services/Auth';
import { CircularProgress } from '@mui/material';

function Login() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [isSignup, setIsSignup] = useState(false);
  const [resendVerificationEmail, setResendVerificationEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setMessage('Failed to login. Please check your credentials.');
      setMessageType('error');
      setOpen(true);
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);

      const user = auth.currentUser; // Get the current user after login
      if (user) {
        if (user.emailVerified) {
          const q = query(collection(db, 'users'), where('email', '==', formData.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDetails = querySnapshot.docs[0].data(); // Assuming email is unique
            console.log('User Details:', userDetails);
            if (!userDetails.emailVerified) {
              updateUserEmailVerifiedStatus(formData.email);
            }
            if (userDetails.role === 'superAdmin') {
              navigate('/superAdmin/dashboard');
            } else if (userDetails.role === 'universityAdmin' || userDetails.role === 'universityUser') {
              navigate('/university/dashboard');
            } else {
              navigate('/student/dashboard');
            }
          } else {
            console.error('No user found with the provided email.');
          }
        } else {
          setResendVerificationEmail(true); // Prompt for verification if not verified
          setMessage('Please verify your email to continue.');
          setMessageType('warning');
          setOpen(true);
        }
      }
    } catch (err) {
      setMessage('Failed to login. Please check your credentials.');
      setMessageType('error');
      setOpen(true);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, mobile, password, confirmPassword } = formData;

    if (!firstName || !lastName || !email || !mobile || !password || !confirmPassword) {
      setMessage('All fields are mandatory');
      setMessageType('error');
      setOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setMessageType('error');
      setOpen(true);
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(password)) {
      setMessage('Password must have at least 8 characters, one uppercase, one lowercase, one special character, and one number.');
      setMessageType('error');
      setOpen(true);
      return;
    }

    try {
      setError('');
      setLoading(true); // Start loading

      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // After creating the user, add the user details to the Firestore 'users' collection
      await setDoc(doc(db, 'users', email), {
        firstName,
        lastName,
        email,
        mobile,
        role: 'student',
        emailVerified: false,
        createdAt: new Date(),
      });

      setMessage('Your account has been successfully created. Please check your email to verify your account.');
      setMessageType('success');
      setOpen(true);
      setIsSignup(false);
      resendVerificationEmail(true);
    } catch (err) {
      setMessage(`Error!! ${err.message}`);
      setMessageType('error');
      setOpen(true);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResendVerifyEmail = () => {
    if (resendVerifyEmail()) {
      setResendVerificationEmail(false)
      setMessage('Verification email base beeen sent successfully. Please check your email to verify your account.')
      setMessageType('success');
      setOpen(true);
    } else {
      setMessage('Something went wrong. Please try again!')
      setMessageType('error');
      setOpen(true);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" fontWeight="bold" style={{ fontSize: 16 }}>
          {isSignup ? 'Sign Up for Your Account' : 'Log in to Your Account'}
        </Typography>
        {loading && (
          <CircularProgress /> // Show loader when loading is true
        )}
        <form
          onSubmit={isSignup ? handleSignup : handleLogin}
          style={{ width: '300px', marginTop: '16px' }}
        >
          {error && <Typography color="error" sx={{ fontSize: '12px' }}>{error}</Typography>}
          {isSignup && (
            <>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Box>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                margin="normal"
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobile"
                margin="normal"
                variant="outlined"
                value={formData.mobile}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Password"
                name="password"
                margin="normal"
                type="password"
                variant="outlined"
                value={formData.password}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                margin="normal"
                type="password"
                variant="outlined"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </>
          )}

          {!isSignup && (
            <>
              {resendVerificationEmail && <Typography color='error'>
                We sent a verification email to your inbox. Please click the link to verify your email and get started.<br />
                Didn't receive the email? Check your spam folder or <Button onClick={handleResendVerifyEmail}>click here to resend the verification email</Button>.
              </Typography>}
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Password"
                margin="normal"
                type="password"
                variant="outlined"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </>
          )}

          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: '#004aad',
              '&:hover': { bgcolor: '#004aad' },
              borderRadius: '8px',
            }}
            type="submit"
          >
            {isSignup ? 'Sign Up Now' : 'Login Now'}
          </Button>
        </form>

        <Typography sx={{ marginTop: '16px' }}>
          {isSignup ? (
            <>
              Already have an account?{' '}
              <Link
                to="#"
                onClick={() => {
                  let formfieldsData = { ...formData }
                  formfieldsData.password = ''
                  setFormData(formfieldsData)
                  setError('')
                  setIsSignup(false)
                }}
                style={{ color: 'rgb(84 121 247)', textDecoration: 'none' }}
              >
                Log in here.
              </Link>
            </>
          ) : (
            <>
              Are you a student?{' '}
              <Link
                to="#"
                onClick={() => {
                  setError('')
                  let formfieldsData = { ...formData }
                  formfieldsData.password = ''
                  setFormData(formfieldsData)
                  setIsSignup(true)
                }}
                style={{ color: 'rgb(84 121 247)', textDecoration: 'none' }}
              >
                Sign up here.
              </Link>
            </>
          )}
        </Typography>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          textAlign: 'center',
          backgroundImage: `url(${bannerImage})`, // Use the imported image
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          color: 'white',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            position: 'absolute', // Ensures text appears on top of the background
            width: '100%',
            backgroundColor: '#004aad',
            bottom: 0,
            left: 0,
            fontWeight: 300,
            opacity: 0.9,
            padding: '24px',
            lineHeight: '38px',
            fontSize: '24px',
          }}
        >
          Empowering bright minds to achieve their dreams, navigate their journey, and connect with brighter futures <br /> — Your success starts here.
        </Typography>
      </Box>
      <MuiSnackbar
        open={open}
        message={message}
        messageType={messageType}
        onClose={handleClose}
      />
    </Box>
  );
}

export default Login;
