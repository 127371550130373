import React from "react";
import { AppBar, Box, Toolbar, Typography, Container, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import Logo from "../assets/WEE_Logo_final.png";

const PromoPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (<>
    <Box sx={{ bgcolor: "#CEDAFF", padding: '20px' }}>
      {/* Header */}
      <AppBar position="static" sx={{ bgcolor: "#CEDAFF", boxShadow: "none" }}>
        <Toolbar>
          <Box component="img" src={Logo} alt="Logo" sx={{ width: 200, ml: 2 }} />
        </Toolbar>
      </AppBar>

      {/* Main Content */}

      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {/* Video Section */}
        <Grid item xs={12} md={6} >
          <Paper sx={{ bgcolor: "black", display: "flex", justifyContent: "center", alignItems: "center", height: isMobile ? 300 : 500 }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Sv6ys7oby2Q"
              title="Promo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: "8px" }}
            ></iframe>
          </Paper>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} md={6} textAlign="center">
          <Typography variant="h6" gutterBottom>
            World Education Evaluation<br />Your Trusted Partner in AI-Powered Academic Transcript Evaluation.
          </Typography>
          {!isMobile && (
            <Box
              component="img"
              src={require("../assets/qrcode.png")}
              alt="QR Code"
              sx={{ width: 150, my: 2 }}
            />
          )}
          <Typography variant="body2" color="textSecondary">
            For any inquiries, <a href="mailto:admin@saininfotek.com" style={{ color: "#1976d2" }}>click here to send an email</a> (admin@saininfotek.com),
            <br /> {!isMobile ? `or scan the QR Code above.` : ''}
          </Typography>
        </Grid>
      </Grid>
    </Box>

    <Container sx={{ bgcolor: "white", py: 4 }} style={{paddingBottom: '32px'}}>
      <Grid container spacing={4}>
        {/* About Us */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            At World Education Evaluation, we are committed to simplifying international academic evaluations through technology-driven solutions.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Our platform bridges this gap by providing a seamless, automated evaluation process.
          </Typography>
          <Typography variant="body1">
            With our user-friendly interface, applicants can upload their academic transcripts in PDF format, and our advanced system will automatically extract subjects and grades.
          </Typography>
        </Grid>

        {/* Our Mission */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Backed by SAIN TECHNOLOGIES LLC, we aim to revolutionize global education by leveraging AI to automate GPA generation from academic transcripts in seconds.
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
            <li>Students applying to universities who need their grades converted instantly.</li>
            <li>Professionals seeking credential validation.</li>
            <li>Institutions assessing international applicants.</li>
          </Typography>
        </Grid>
      </Grid>
    </Container>

    {/* Footer */}
    <Box sx={{ bgcolor: "#3464F3", position: 'fixed', left: 0, bottom: 0, width: '100%', color: "white", textAlign: "center", py: 2, mt: 4 }}>
      <Typography>Powered by SAIN TECHNOLOGIES LLC</Typography>
    </Box>
  </>
  );
};

export default PromoPage;