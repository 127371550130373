import React, { useState } from 'react';
import Sidebar from './commonComponents/LeftNavPanel';
import AppHeader from './commonComponents/Header';
import { Box } from '@mui/material';
import DocumentsList from './commonComponents/DocumentList';
import FileUpload from '../../FileUpload';

function GenerateGPA() {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <AppHeader />

            <Box sx={{ display: 'flex' }}>
                <Sidebar selectedIndex={selectedIndex} handleListItemClick={handleListItemClick} />

                <Box            
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: 3,                        
                    }}
                >     
                     <FileUpload />
                </Box>
            </Box>
        </Box>
    );
}

export default GenerateGPA;
