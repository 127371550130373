import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyATeD3Uc3y3q3v5MhTxgQEADG8j7TxMUjk",
  authDomain: "sainsoft-45d33.firebaseapp.com",
  projectId: "sainsoft-45d33",
  storageBucket: "sainsoft-45d33.appspot.com",
  messagingSenderId: "360220597742",
  appId: "1:360220597742:web:1c56958fad6edd375ac2ff",
  measurementId: "G-JYCTXH1L4C"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); 