import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {
    avatarUrl: '',
    role: '',
    name: '',
    email: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateUserAvatar: (state, action) => {
      state.userDetails.avatarUrl = action.payload;
    },
    clearUserDetails: (state) => {
      state.userDetails = initialState.userDetails;
    },
  },
});

export const { setUserDetails, updateUserAvatar, clearUserDetails } = userSlice.actions;

export default userSlice.reducer;
