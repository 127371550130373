import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FileUpload from './FileUpload';
import Login from './pages/Login';
import Header from './components/Header';
import UniversityList from './pages/admin/universities/UniversityList';
import UsersList from './pages/admin/users/UsersList';
import { useUser } from './components/useUser';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import StudentDashboard from './pages/student/Dashboard';
import StudentProfile from './pages/student/Profile';
import StudentDocuments from './pages/student/Documents';
import StudentApplications from './pages/student/Applications';
import StudentUniversities from './pages/student/StudentUniversities';
import SuperAdminDashboard from './pages/superAdmin/Dashboard';
import Universities from './pages/superAdmin/Universities';
import UniversityDashboard from './pages/university/Dashboard';
import UniversityProfile from './pages/university/components/Profile';
import ManageUniversity from './pages/admin/universities/ManageUniversity';
import UniversityStudents from './pages/university/Students';
import GenerateGPAForUniversity from './pages/university/GenerateGPA';
import UniversityUsers from './pages/university/Users';
import UniversityCourses from './pages/university/Courses';
import ManageUniversityProfile from './pages/university/UniversityProfile';
import GenerateGPA from './pages/student/GenerateGPA';
import PromoPage from './pages/Promotion';
const App = () => {
  const { currentUser, loading } = useUser();

  // Protected route wrapper component
  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" replace />;
  };

  // While loading, you can return a loading spinner or nothing
  if (loading) {
    return <div>Loading...</div>; // Or any loading component
  }

  return (
    <div className="App">
      {/* <Router>
       

        <Routes>
          <Route path="/login" element={<Login />} />

          
          <Route path="/" element={<ProtectedRoute><FileUpload /></ProtectedRoute>} />
          <Route path="/admin/universities" element={<ProtectedRoute><UniversityList /></ProtectedRoute>} />
          <Route path="/admin/users" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/student/dashboard" element={<StudentDashboard />} />
          <Route path="/student/profile" element={<StudentProfile />} />
          <Route path="/student/documents" element={<StudentDocuments />} />
          <Route path="/student/universities" element={<StudentUniversities />} />
          <Route path="/student/applications" element={<StudentApplications />} />
          <Route path="/student/generate-gpa" element={<GenerateGPA />} />

          <Route path="/university/dashboard" element={<UniversityDashboard />} />
          <Route path="/university/profile" element={<ManageUniversityProfile />} />
          <Route path="/university/students" element={<UniversityStudents />} />
          <Route path="/university/generateGPA" element={<GenerateGPAForUniversity />} />
          <Route path="/university/users" element={<UniversityUsers />} />
          <Route path="/university/courses" element={<UniversityCourses />} />

          <Route path="/superAdmin/dashboard" element={<SuperAdminDashboard />} />
          <Route path="/superAdmin/universities" element={<Universities />} />
          
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router> */}
      <PromoPage />
    </div>
  );
};

export default App;
